import { NgModule, importProvidersFrom } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { growthSlotsFeature } from './slots/state/growth-slots.reducers';
import { GrowthSlotsEffects } from './slots/state/growth-slots.effects';
import { authGuard } from '@authorization/guards/auth.guard';
import { adminRedirectGuard } from '@authorization/guards/adminRedirect.guard';
import { UsersComponent } from './users/component/users.component';
import { usersFeature } from './users/state/users.reducers';
import { UsersEffects } from './users/state/users.effects';
import { scheduleMainFeature } from '@features/schedules-new/state/schedules.reducer';
import { ScheduleMainEffects } from '@features/schedules-new/state/schedules.effects';

export const BASIC_SCHEDULER_URL = 'basic-scheduler';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [authGuard],
    children: [
      {
        path: '',
        redirectTo: 'schedules',
        pathMatch: 'full'
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [adminRedirectGuard],
        providers: [
          importProvidersFrom(StoreModule.forFeature(usersFeature)),
          importProvidersFrom(EffectsModule.forFeature(UsersEffects))
        ]
      },
      {
        path: 'markets',
        loadChildren: async () => (await import('./markets/markets.module')).MarketsModule
      },
      {
        path: 'schedules',
        loadChildren: async () => (await import('./schedules/schedules.module')).SchedulesModule
      },
      {
        path: 'schedules-new',
        loadChildren: async () => (await import('./schedules-new/routes')).schedulesRoutes,
        providers: [
          importProvidersFrom(StoreModule.forFeature(scheduleMainFeature)),
          importProvidersFrom(EffectsModule.forFeature(ScheduleMainEffects))
        ]
      },
      {
        path: 'slots',
        loadChildren: async () => (await import('./slots/slots.module')).SlotsModule,
        providers: [
          importProvidersFrom(StoreModule.forFeature(growthSlotsFeature)),
          importProvidersFrom(EffectsModule.forFeature(GrowthSlotsEffects))
        ]
      },
      {
        path: 'duty-limitations',
        loadChildren: () => import('./duty-limitations/routes')
      },
      {
        path: 'spreads',
        loadChildren: async () => (await import('./spreads/routes')).spreadRoutes
      },
      {
        path: 'engineering',
        loadChildren: () => import('./engineering/routes')
      },
      {
        path: 'historic',
        loadChildren: async () => (await import('./historic-slots/historic-slots.module')).HistoricSlotsModule
      },
      {
        path: 'operations',
        loadChildren: async () => (await import('./operations/operations.module')).OperationsModule
      },
      {
        path: 'settings',
        loadChildren: async () => (await import('./settings/settings.module')).SettingsModule
      },
      {
        path: BASIC_SCHEDULER_URL,
        loadChildren: async () => (await import('./basic-scheduler/routes')).basicSchedulerRoutes
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ComponentsRoutingModule {}
