import { OptimiserStatus } from '@features/schedules-new/schedules.models';
import { OptimiserResultDto } from '@features/schedules-new/flow/flow.model';

export interface ScheduleMainState {
  optimiserResult: OptimiserResultDto;
  optimiserStatus: OptimiserStatus;
  optimiserStarted: string | undefined;
  isLoading: boolean;
}

export const initialScheduleMainState: ScheduleMainState = {
  optimiserResult: undefined,
  optimiserStatus: 'UNKNOWN',
  optimiserStarted: undefined,
  isLoading: undefined
};
