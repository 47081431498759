import { createFeature, createReducer, on } from '@ngrx/store';
import { initialScheduleMainState, ScheduleMainState } from '@features/schedules-new/state/schedules.state';
import { apiScheduleFetched } from '@features/schedules-new/schedules-list/state/schedules-list.actions';
import {
  apiSchedulesReceivedStatusUpdate,
  apiStartOptimiserErrorsReturned,
  apiStopOptimiserSuccess,
  scheduleMainButtonResumeOptimiserClicked,
  scheduleMainButtonStartOptimiserClicked,
  scheduleMainPageDestroyed,
  schedulesListPageLoaded
} from '@features/schedules-new/state/schedules.actions';
import { userloggedOutSuccess } from '@state/app.actions';
import {
  apiBulkFreezeLineSuccess,
  apiBulkUnfreezeLineSuccess,
  apiFetchOptimiserResultFailed,
  apiFetchOptimiserResultSuccess,
  apiFreezeLineSuccess,
  apiLockSectorSuccess,
  apiUnfreezeLineSuccess,
  shouldPollConditionPassSuccess,
  updateAssociatedSectorSlotsSuccess
} from '@features/schedules-new/flow/state/flow.actions';
import { OptimiserResultLineDto, OptimiserSectorDto } from '@features/schedules-new/flow/flow.model';
import { AssociatedSlot } from '@features/historic-slots/historic-slots.models';

export const scheduleMainReducer = createReducer<ScheduleMainState>(
  initialScheduleMainState,
  on(
    apiSchedulesReceivedStatusUpdate,
    (state, action): ScheduleMainState => ({
      ...state,
      optimiserStatus: action.status,
      optimiserStarted: action.optimiserStarted
    })
  ),
  on(schedulesListPageLoaded, (): ScheduleMainState => initialScheduleMainState),
  on(
    apiFetchOptimiserResultSuccess,
    (state, action): ScheduleMainState => ({
      ...state,
      optimiserResult: action.optimiserResult
    })
  ),
  on(apiLockSectorSuccess, (state, { line, sector }): ScheduleMainState => {
    const updatedLines = state.optimiserResult.lines.map((lineItem: OptimiserResultLineDto) => {
      if (lineItem.schedule_flying_line_id === line.schedule_flying_line_id) {
        const updatedLine = { ...lineItem };
        updatedLine.sectors = updatedLine.sectors.map((sectorItem) => {
          if (sectorItem[0][11] === sector.id) {
            const newSectorItem: OptimiserSectorDto = [[...sectorItem[0]], [...sectorItem[1]]];
            newSectorItem[0][13] = sector.dottedBorder ? 0 : 3;
            return newSectorItem;
          } else {
            return sectorItem;
          }
        });
        return updatedLine;
      }
      return lineItem;
    });

    return {
      ...state,
      optimiserResult: {
        ...state.optimiserResult,
        lines: updatedLines
      }
    };
  }),
  on(apiFreezeLineSuccess, apiUnfreezeLineSuccess, (state, action): ScheduleMainState => {
    return {
      ...state,
      optimiserResult: {
        ...state.optimiserResult,
        lines: state.optimiserResult.lines.map((lineItem: OptimiserResultLineDto) => {
          if (lineItem.schedule_flying_line_id === action.line.schedule_flying_line_id) {
            return {
              ...lineItem,
              frozen: action.type === apiFreezeLineSuccess.type,
              sectors: lineItem.sectors.map((sectorItem) => {
                const newSectorItem: OptimiserSectorDto = [[...sectorItem[0]], [...sectorItem[1]]];
                newSectorItem[0][13] = action.type === apiFreezeLineSuccess.type ? 1 : 0;
                return newSectorItem;
              })
            };
          } else {
            return lineItem;
          }
        })
      }
    };
  }),
  on(apiBulkFreezeLineSuccess, apiBulkUnfreezeLineSuccess, (state, action): ScheduleMainState => {
    return {
      ...state,
      optimiserResult: {
        ...state.optimiserResult,
        lines: state.optimiserResult.lines.map((lineItem: OptimiserResultLineDto) => {
          if (action.lines.includes(lineItem.schedule_flying_line_id)) {
            return {
              ...lineItem,
              frozen: action.type === apiBulkFreezeLineSuccess.type,
              sectors: lineItem.sectors.map((sectorItem) => {
                const newSectorItem: OptimiserSectorDto = [[...sectorItem[0]], [...sectorItem[1]]];
                newSectorItem[0][13] = action.type === apiBulkFreezeLineSuccess.type ? 1 : 0;
                return newSectorItem;
              })
            };
          } else {
            return lineItem;
          }
        })
      }
    };
  }),
  on(updateAssociatedSectorSlotsSuccess, (state, action): ScheduleMainState => {
    const slotType: 'DEPARTURE' | 'ARRIVAL' = action?.slotType;
    const associatedSlot: AssociatedSlot = action.associatedSlot;
    return {
      ...state,
      optimiserResult: {
        ...state.optimiserResult,
        lines: state.optimiserResult.lines.map((lineItem: OptimiserResultLineDto) => {
          return lineItem.schedule_flying_line_id === action.lineId
            ? {
                ...lineItem,
                sectors: lineItem.sectors.map((sectorItem) => {
                  if (sectorItem[0][11] !== action.sectorId) {
                    return sectorItem;
                  }
                  const newSectorItem: OptimiserSectorDto = [[...sectorItem[0]], [...sectorItem[1]]];
                  if (slotType === 'DEPARTURE') {
                    newSectorItem[0][18] = action.departureSlotsId ?? associatedSlot.id;
                    newSectorItem[0][27] = 1;
                  } else if (slotType === 'ARRIVAL') {
                    newSectorItem[0][19] = action.arrivalSlotId ?? associatedSlot.id;
                    newSectorItem[0][28] = 1;
                  }
                  return newSectorItem;
                })
              }
            : lineItem;
        })
      }
    };
  }),
  on(
    apiStopOptimiserSuccess,
    (state): ScheduleMainState => ({
      ...state,
      optimiserStatus: 'COMPLETE'
    })
  ),
  on(
    scheduleMainButtonStartOptimiserClicked,
    scheduleMainButtonResumeOptimiserClicked,
    scheduleMainButtonStartOptimiserClicked,
    (state): ScheduleMainState => ({
      ...state,
      optimiserStatus: 'STARTING'
    })
  ),
  on(
    apiScheduleFetched,
    (state, action): ScheduleMainState => ({
      ...state,
      optimiserStatus: action.schedule.status,
      optimiserStarted: action.schedule.optimiser_start_time
    })
  ),
  on(
    shouldPollConditionPassSuccess,
    apiFetchOptimiserResultFailed,
    apiFetchOptimiserResultSuccess,
    (state, action): ScheduleMainState => {
      return {
        ...state,
        isLoading: action.type === shouldPollConditionPassSuccess.type
      };
    }
  ),
  on(scheduleMainPageDestroyed, (): ScheduleMainState => initialScheduleMainState),
  on(
    apiSchedulesReceivedStatusUpdate,
    (state, action): ScheduleMainState => ({
      ...state,
      optimiserStatus: action.status,
      optimiserStarted: action.optimiserStarted
    })
  ),
  on(
    apiScheduleFetched,
    (state, action): ScheduleMainState => ({
      ...state,
      optimiserStatus: action.schedule.status,
      optimiserStarted: action.schedule.optimiser_start_time
    })
  ),
  on(
    apiStopOptimiserSuccess,
    (state): ScheduleMainState => ({
      ...state,
      optimiserStatus: 'COMPLETE'
    })
  ),
  on(
    scheduleMainButtonStartOptimiserClicked,
    scheduleMainButtonResumeOptimiserClicked,
    (state): ScheduleMainState => ({
      ...state,
      optimiserStatus: 'STARTING'
    })
  ),
  on(
    apiStartOptimiserErrorsReturned,
    (state): ScheduleMainState => ({
      ...state,
      optimiserStatus: 'AVAILABLE'
    })
  ),
  on(userloggedOutSuccess, (): ScheduleMainState => initialScheduleMainState)
);

export const scheduleMainFeature = createFeature({
  name: 'schedules-main',
  reducer: scheduleMainReducer
});
