import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Injectable } from '@angular/core';
import { SchedulesHttpService } from '@features/schedules-new/schedules.http.service';
import { selectCurrentRouteState } from '@state/router.selector';
import { forkJoin, map, of, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { apiScheduleFetched } from '@features/schedules-new/schedules-list/state/schedules-list.actions';
import {
  apiClearOptimiserSuccess,
  apiResumeOptimiserSuccess,
  apiStartOptimiserErrorsReturned,
  apiStartOptimiserSuccess,
  apiStopOptimiserSuccess,
  scheduleMainButtonClearOptimiserClicked,
  scheduleMainButtonResumeOptimiserClicked,
  scheduleMainButtonStartOptimiserClicked,
  scheduleMainButtonStopOptimiserCancelled,
  scheduleMainButtonStopOptimiserClicked,
  scheduleMainComponentInitialized
} from '@features/schedules-new/state/schedules.actions';
import { catchError, concatMap, tap } from 'rxjs/operators';
import { ModalService } from '@shared/components/dialogs/modal.service';
import { scheduleFetchedFailedOnScheduleDetailsPage } from '@state/app.actions';
import { apiFlyingLinesCreateTargetScheduleSuccess } from '@features/schedules-new/flow/state/flow.actions';

@Injectable()
export class ScheduleMainEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private modalService: ModalService,
    private schedulesHttpService: SchedulesHttpService
  ) {}

  getScheduleInfo = createEffect(() => {
    return this.actions$.pipe(
      ofType(scheduleMainComponentInitialized, apiFlyingLinesCreateTargetScheduleSuccess),
      concatLatestFrom(() => this.store.select(selectCurrentRouteState)),
      switchMap(([, routeState]) => {
        const scheduleId = routeState.params.id;
        // const scheduleId =
        //   action.type === apiFlyingLinesCreateTargetScheduleSuccess.type ? action.schedule.id : routeState.params.id;
        return this.schedulesHttpService.getScheduleInfo(scheduleId, routeState.params.season);
      }),
      map((schedule) => apiScheduleFetched({ schedule })),
      catchError(() => {
        return of(scheduleFetchedFailedOnScheduleDetailsPage());
      })
    );
  });

  startOptimiserEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(scheduleMainButtonStartOptimiserClicked),
      concatLatestFrom(() => this.store.select(selectCurrentRouteState)),
      switchMap(([_action, routeState]) =>
        this.schedulesHttpService.startOptimiser(routeState.params.id).pipe(
          map(() => apiStartOptimiserSuccess()),
          catchError(() => of(apiStartOptimiserErrorsReturned()))
        )
      )
    );
  });

  resumeOptimiserEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(scheduleMainButtonResumeOptimiserClicked),
      concatLatestFrom(() => this.store.select(selectCurrentRouteState)),
      switchMap(([_action, routeState]) => this.schedulesHttpService.resumeOptimiser(routeState.params.id)),
      map(() => apiResumeOptimiserSuccess())
    );
  });

  stopOptimiserEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(scheduleMainButtonStopOptimiserClicked),
      concatLatestFrom(() => this.store.select(selectCurrentRouteState)),
      concatMap(([, routeState]) =>
        forkJoin([
          this.modalService.showConfirmDialog('Confirm that you really want to stop optimiser...', 'Stop'),
          of(routeState)
        ])
      ),
      switchMap(([confirmed, routeState]) => {
        if (confirmed) {
          return this.schedulesHttpService.stopOptimiser(routeState.params.id);
        } else {
          return of(undefined);
        }
      }),
      map((result) => {
        if (result) {
          return apiStopOptimiserSuccess();
        } else {
          return scheduleMainButtonStopOptimiserCancelled();
        }
      })
    );
  });

  clearOptimiserEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(scheduleMainButtonClearOptimiserClicked),
      concatLatestFrom(() => this.store.select(selectCurrentRouteState)),
      tap(() => this.modalService.showLoader('Clearing optimiser...')),
      switchMap(([_action, routeState]) => this.schedulesHttpService.clearOptimiser(routeState.params.id)),
      tap(() => this.modalService.hideLoader()),
      map(() => apiClearOptimiserSuccess())
    );
  });
}
